import type { NextPage } from 'next';
import style from './CloudsArea.module.css';

const CloudsArea: NextPage = () => {
  return (
    <div className={style.root}>
      <div className={style.small} />
      <div className={style.medium} />
      <div className={style.medium} />
      <div className={style.tiny} />
      <div className={style.small} />
      <div className={style.tiny} />
    </div>
  );
};

export default CloudsArea;
