import { NextPage } from 'next';
import { ReactNode } from 'react';
import Image from 'next/image';
import styles from './Underground.module.css';
import FourCoins from '../../assets/four-coins.png';
import DeadDilduck from '../../assets/dead-dilduck.png';
import DilduckSkull from '../../assets/dilduck-skull.png';
import KingDead from '../../assets/king-dead.png';
import ChestHole from '../../assets/chest-hole.png';
import TwoCoins from '../../assets/two-coins.png';
import Planet from '../../assets/alanverse.png';

type UndergroundProps = {
  children: ReactNode;
};

const Underground: NextPage<UndergroundProps> = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.spaceFade} />
      <div className={styles.alanverse}>
        <Image src={Planet} alt="Alanverse" layout="responsive" />
      </div>
      <div className={styles.fourCoins}>
        <Image src={FourCoins} alt="four-coins" layout="fixed" />
      </div>
      <div className={styles.deadDilduck}>
        <Image src={DeadDilduck} alt="dead-dilduck" layout="fixed" />
      </div>
      <div className={styles.dilduckSkull}>
        <Image src={DilduckSkull} alt="dilduck-skull" layout="fixed" />
      </div>
      <div className={styles.dilduckSkull2}>
        <Image src={DilduckSkull} alt="dilduck-skull" layout="fixed" />
      </div>
      <div className={styles.kingDead}>
        <Image src={KingDead} alt="king-dead" layout="fixed" />
      </div>
      <div className={styles.chestHole}>
        <Image src={ChestHole} alt="cest-hole" layout="fixed" />
      </div>
      <div className={styles.twoCoins}>
        <Image src={TwoCoins} alt="two-coins" layout="fixed" />
      </div>
      <div className={styles.twoCoins2}>
        <Image src={TwoCoins} alt="two-coins" layout="fixed" />
      </div>
      {children}
    </div>
  );
};

export default Underground;
