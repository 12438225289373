import { NextPage } from 'next';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import CloudsArea from './CloudsArea';
import GameMock from './GameMock';
import styles from './Home.module.css';
import Headers from '../Headers/Headers';
import config from '../../config';
import { useAppContext } from '../../shared/context/App.context';
import { useUserContext } from '../../shared/context/User.context';
import { QueryParamsEnum } from '../../shared/consts/queryParamsEnum';
import PageTitleEnum from '../../shared/consts/pageTitleEnum';
import HeadTitle from '../HeadTitle/HeadTitle';
import Underground from './Underground';
import Footer from '../Footer/Footer';
import MuskShine from './MuskShine';
import GetMuskHome from './GetMusk';
import FollowOn from './FollowOn';
import WhyMusk from './WhyMusk';

const Home: NextPage = () => {
  const { query: queryParams, push: routerPush } = useRouter();
  const connectFromGame = queryParams[QueryParamsEnum.ConnectFromGame];
  const { setDisplayUserAuth } = useAppContext();
  const { isUserConnected } = useUserContext();

  useEffect(() => {
    if (connectFromGame !== 'true') {
      return;
    }

    if (isUserConnected) {
      setDisplayUserAuth(false);
      routerPush(config.routes.game);
      return;
    }

    setDisplayUserAuth(true);
  }, [connectFromGame, isUserConnected, routerPush, setDisplayUserAuth]);

  return (
    <div className={styles.root}>
      <HeadTitle title={PageTitleEnum.HOME} />
      <div className={styles.hero}>
        <Headers />
        <div className={styles.content}>
          <CloudsArea />
          <MuskShine />
          <GetMuskHome />
          <FollowOn />
        </div>
        <GameMock />
      </div>
      <div className={styles.separator} />
      <Underground>
        <WhyMusk />
      </Underground>
      <Footer />
    </div>
  );
};

export default Home;
