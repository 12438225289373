import { ReactElement } from 'react';
import cx from 'classnames';
import styles from './FollowOn.module.css';
import config from '../../config';
import TelegramIcon from '../../assets/telegram-icon.svg';
import XIcon from '../../assets/x-icon.svg';

const FollowOn = (): ReactElement => {
  return (
    <div className={styles.root}>
      <div className={cx('shadow-text-gray-left', styles.follow)}>JOIN THE REVOLUTION</div>
      <div className={styles.iconsWrapper}>
        <a href={config.routes.twitter} target="_blank" className={styles.followLink} rel="noreferrer">
          <XIcon />
        </a>
        <a href={config.routes.telegram} target="_blank" className={styles.followLink} rel="noreferrer">
          <TelegramIcon />
        </a>
      </div>
    </div>
  );
};

export default FollowOn;
