import { ReactElement } from 'react';
import cx from 'classnames';

import Image from 'next/image';
import styles from './WhyMusk.module.css';
import Title from '../Title/Title';
import CharactersGroup from '../../assets/characters/charactersGroup.svg';
import RedZigZag from '../../assets/redZigZag.svg';
import AlanJumpCoins from '../../assets/characters/alanJumpCoins.png';
import BuningEarth from '../../assets/characters/burningEarth.png';
import HeartBrain from '../../assets/heartBrain.png';
import MuskBlink from '../../assets/muskBlink.png';
import Astallion from '../../assets/astallion.png';
import ShinyButton from '../Buttons/ShinyButton';
import { ShinyButtonColorEnum } from '../../shared/consts/shinyButtonColors';
import config from '../../config';

const WhyMusk = (): ReactElement => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Title>WHY $MUSK?</Title>
      </div>
      <div className={styles.whiteBox}>
        Dear humans, <br /> we are The Alans from <br />
        <strong className={styles.violet}>The Alanverse</strong>.
      </div>
      <CharactersGroup className={styles.charactersGroup} />
      <div className={cx(styles.whiteBox, styles.boxWithBottomShape)}>
        <p>
          After many years of consideration we have decided to directly contact you through means we calculated the most
          convenient.
        </p>
        <p>
          Through a <strong className={styles.mustard}>Meme Coin</strong>.
        </p>
        <div className={styles.alanJumpCoins}>
          <Image priority src={AlanJumpCoins} />
        </div>
        <p className={styles.negativeBottom}>
          The reason for <br />
          contacting you is, <br />
          that your <br />
          civilization is in a <br />
          steady spiral <br />
          to its <br />
          devastating <br />
          demise.
        </p>
        <RedZigZag className={styles.boxBottomShape} />
      </div>
      <div className={cx(styles.textSection, styles.underZigZag)}>
        As you may have <br />
        noticed, things on <br /> <span className={styles.blue}>your</span> <span className={styles.green}>planet</span>{' '}
        have <br />
        seemed to <br />
        go down to a pretty <br />
        awful state, but we are <br />
        telling you it will get a <br />
        whole lot worse. <br /> <span className={styles.yellow}>We think we can help you.</span>
      </div>
      <div className={cx(styles.textSection, styles.smallMarginBottom)}>
        <strong className={styles.pink}>The Alan race</strong> <br />
        has been existing for <br />
        trillions of years <br />
        and in that time we have evolved over our <br />
        differences and struggles, <br />
        and no longer have any <br />
        of that bad stuff <br />
        happening in our <br />
        civilisation. <br />
        If you let us, we will fix <br />
        your planet too.
      </div>
      <div className={styles.burningEarth}>
        <Image priority src={BuningEarth} />
      </div>
      <div className={cx(styles.textSection, styles.smallMarginBottom)}>
        One of the reasons the <br />
        Alans are so peaceful, <br />
        fulfilled and loving, <br />
        is that through evolution <br />
        our <span className={styles.red}>hearts</span> <br />
        and <span className={styles.turquoise}>brains</span> <br />
        have fused together and <br />
        we no longer have any <br />
        inner conflicts within <br />
        ourselves.
      </div>
      <div className={styles.heartBrain}>
        <Image priority src={HeartBrain} />
      </div>
      <div className={styles.whiteBox}>
        This left us a little bored, so we started surveilling multiple less evolved life forms, and their journey
        through evolution. <br />
        The <span className={styles.midnightBlue}>humans</span> quickly became a crowd favorite and we just
        couldn&apos;t stand watching you destroy yourselves any longer.
      </div>
      <div className={cx(styles.textSection, styles.smallMarginBottom)}>
        One of our first attempts, <br />
        was creating <strong className={styles.yellow}>Bitcoin</strong>, <br />
        or as its proper name: <br />
        <span className={styles.pink}>B</span>utt <span className={styles.pink}>I</span>mplant{' '}
        <span className={styles.pink}>T</span>rust -coin, <br />
        as a means for making <br />
        value easy for <br />
        everybody. <br />
        But that was just a test <br />
        run for the real deal: The <br />
        eternal value coin.
      </div>
      <div className={styles.muskBlink}>
        <Image priority src={MuskBlink} />
      </div>
      <div className={styles.textSection}>
        Our civilisation is built on <br />
        <span className={styles.red}>Love</span>, <span className={styles.purple}>Compassion</span> and <br />
        <span className={styles.turquoise}>Eternal Value</span>, <br />
        And so will yours, <br />
        humans, <br />
        if you follow us.
      </div>
      <div className={cx(styles.textSection, styles.smallMarginBottom)}>
        Kinda regards, <br />
        <strong>The Alans</strong>
      </div>
      <div className={styles.astallion}>
        <Image priority src={Astallion} />
      </div>
      <div className={styles.bottomButtons}>
        <ShinyButton dense medium grayText color={ShinyButtonColorEnum.turquoise} toPage={config.routes.roadmap}>
          ROAD MAP
        </ShinyButton>
        <ShinyButton dense medium grayText color={ShinyButtonColorEnum.silver} toPage={config.routes.tokenomics}>
          TOKENOMICS
        </ShinyButton>
      </div>
    </div>
  );
};

export default WhyMusk;
