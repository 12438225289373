import type { NextPage } from 'next';
import Image from 'next/image';
import styles from './GameMock.module.css';

const GameMock: NextPage = () => {
  return (
    <div className={styles.root}>
      <div className={styles.bird} />
      <div className={styles.horizont} />
      <div className={styles.terrain}>
        <div className={styles.sand} />
        <div className={styles.bush} />
      </div>
      <div className={styles.character} />
      <p className={styles.bottomText}>
        <span className={styles.bottomArrowWrapper}>
          <Image priority layout="fill" src="/arrow-bottom.svg" alt="Bottom arrow" />
        </span>
        <span>KEEP SCROLLING</span>
        <span className={styles.bottomArrowWrapper}>
          <Image priority layout="fill" src="/arrow-bottom.svg" alt="Bottom arrow" />
        </span>
      </p>
    </div>
  );
};

export default GameMock;
