import { ReactElement } from 'react';
import styles from './GetMusk.module.css';

import GoldAlan from '../../assets/characters/gold.svg';
import Rainbow from '../../assets/characters/raibou.svg';
import PinkSpeeder from '../../assets/characters/pink-speeder-thong.svg';
import ButtonArrow from './ButtonArrow';
import ShinyButton from '../Buttons/ShinyButton';
import config from '../../config';
import { ShinyButtonColorEnum } from '../../shared/consts/shinyButtonColors';

const GetMuskHome = (): ReactElement => {
  return (
    <div className={styles.root}>
      <div className={styles.tripleMusk}>
        <GoldAlan />
        <Rainbow />
        <PinkSpeeder />
      </div>
      <div className={styles.pinkBarText}>
        How to get <span>$MUSK?</span>
      </div>
      <div className={styles.buttons}>
        <ButtonArrow>
          <ShinyButton
            disabled
            small
            grayText
            shineAnimation
            toPage={config.routes.game}
            className={styles.buttonCustomSize}
          >
            <strong>PLAY GAME</strong>
          </ShinyButton>
        </ButtonArrow>
        <span className={styles.or}> OR </span>
        <ButtonArrow>
          <ShinyButton
            small
            grayText
            shineAnimation
            color={ShinyButtonColorEnum.green}
            toPage={config.routes.buy}
            className={styles.buttonCustomSize}
          >
            <strong>BUY NOW</strong>
          </ShinyButton>
        </ButtonArrow>
      </div>
    </div>
  );
};

export default GetMuskHome;
