import { ReactElement, ReactNode } from 'react';
import styles from './ButtonArrow.module.css';
import Arrow from '../../assets/arrowDown.svg';

type ButtonArrowProps = {
  children: ReactNode;
};

const ButtonArrow = ({ children }: ButtonArrowProps): ReactElement => {
  return (
    <div className={styles.root}>
      <Arrow />
      {children}
    </div>
  );
};

export default ButtonArrow;
