import { ReactElement } from 'react';

import Image from 'next/image';
import Title from '../Title/Title';
import styles from './MuskShine.module.css';

import MuskShineImg from '../../assets/musk-shine.svg';
import DollarMusk from '../../assets/$MUSK.png';

const MuskShine = (): ReactElement => {
  return (
    <div className={styles.root}>
      <MuskShineImg className={styles.shine} />
      <Image priority src={DollarMusk} className={styles.musk} />
      <Title className={styles.title}>
        <strong>
          One coin to <br />
          rule them all
        </strong>
      </Title>
    </div>
  );
};

export default MuskShine;
